@import '@savvaslearning/lst-style-variables/variables.scss';
// colors - Light Theme
// http://ux.pearsonschool.com/design/colors/easybridge/
$black: #000;
$white: #FFFFFF;
$grayDarker: #3f3f3f;
$grayDark: #515659;
$grayMedium: #555555;
$gray: #7b7b7b;
$grayLight: #cfcfcf;
$grayLightest: #f3f3f3;
$grayWhite: #f9f9f9;
$blueDark: #196eae;
$blue: #026ecb;
$blueLight: #4088b9;
$teal: #30cd87;
$red: #cb333f;
$redDark: #CC333F;
$orange: #ee7421;
$grayDarkest: #333333;
$bluePrimary: #006BE0;
$whiteLight: #FBFBFB;

// font variables
$font-size-x-small: 12px;
$font-size-small: 14px;
$font-size-medium: 16px;
$font-size-large: 18px;
$font-size-x-large: 24px;
$font-weight-thin: 100;
$font-weight-ultraLight: 200;
$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-thick: 600;
$font-weight-bold: 700;

@mixin info-form-row-label {
    font-weight: $font-weight-ultraLight ;
    font-size: $font-size-small;
}

@mixin info-form-wrap {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
}
