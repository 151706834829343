@import 'bootstrap/dist/css/bootstrap.css';
@import '@lst/font-avenir/font-avenir.css';
@import '@lst/k12_universal_icons/style.css';
@import 'styles/_variables.scss';
@import '@savvaslearning/lst-style-variables/variables.scss';
@import '@savvaslearning/cel-components/dist-stencil/build/lib.css';
@import '@savvaslearning/cel-components/dist-stencil/lib/fonts/font-open-sans/font-open-sans.css';
@import '@savvaslearning/cel-components/dist-stencil/lib/fonts/font-poppins/font-poppins.css';

.brand-dark {
    background-color: #505759 !important; /* bootstrap override */
}

a {
    color: $blueLight;
}
// bootstrap override
// TODO - figure out theme override via Sass variables
//      - https://getbootstrap.com/docs/4.1/getting-started/theming/#theme-colors
.text-primary {
    color: $blueLight !important;
}

// typography
body {
    font-family: 'Avenir', 'sans-serif';
    overflow: auto;
}
h1 {
    color: $blue;
    font-weight: 200;
    font-style: normal;
    font-size: 2.0rem;
}

// bootstrap nav tabs override
.nav-tabs {
    border-bottom: 2px solid $grayLight;
}
.nav-tabs .nav-item {
    margin-bottom: -2px;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef $grayLight;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: $blue;
    background-color: #fff;
    border-color: $grayLight $grayLight #fff;
}
.nav-tabs .nav-link {
    border: 2px solid transparent;
    border-top-left-radius: 0.0rem;
    border-top-right-radius: 0.0rem;
}

.tabset__tab-content {
    border-width: 2px !important;
    border-color: $grayLight !important;
}


/* Sticky footer styles (bootstrap)
-------------------------------------------------- */
html {
    height: -webkit-fill-available;
}
body {
    /* Margin bottom by footer height */
    margin: 0;
    min-height: -webkit-fill-available;
    height: 100%;
    width: 100%;
}

.mat-icon-button {
    background-color: transparent;
}

.hidePaginatorBtn .mat-icon-button {
    &[disabled]{
        display: none;
    }
}

.app-main {
    min-height: -webkit-fill-available;
    height: 100%;
    width: 100%;
    flex-direction: column;
    position: relative;
    display: flex;
    flex-grow: 1;
}

.spinner  {
    .la-ball-spin-clockwise {
        color: $black !important;
    }
    .overlay {
        background-color: rgba(0, 0, 0, 0.2) !important;
        position: fixed !important;
    }
}
